import React from "react";
import { Helmet } from "react-helmet";

const HomePage = () => (
  <div className="page">
    <Helmet>
      <meta charSet="utf-8" />
      <title>Mohtasim</title>
      <link rel="canonical" href="https://mohtasim.com/" />
    </Helmet>
    <div className="section">
      <div className="text-section">
        <p className="main-text">Mohtasim Chowdhury</p>
        <p className="text-block">email: hello at mohtasim dot com</p>
      </div>
    </div>
  </div>
);

export default HomePage;
